@import 'variables'
@import '/styles/variables'

.Links
  a + a
    margin-left: 1em

  svg
    width: 24px
    fill: currentColor

  +light-mode
    color: lighten(black, 66%)
    strong
      color: $text-darker
    a
      color: inherit
      &:hover
        color: lighten(black, 33%)

  +dark-mode
    +soften-images
    color: darken(white, 66%)
    strong
      color: $text-lighter
    a
      color: inherit
      &:hover
        color: darken(white, 33%)
