@import 'variables'
.Articles
  a, a:hover
    text-decoration: none
    color: inherit

  .list-item
    display: block
    transition: 0.2s all

    small
      color: lighten(black, 66%)
      +dark-mode
        color: darken(white, 44%)

    &.pinned
      a:after
        content: ' *'
    &:hover
      transform: scale(1.05)
      +light-mode
        padding-left: 2em
        background: $bg-light
      +dark-mode
        padding-left: 2em
        background: $bg-darker

    padding: 1em 0
    border-bottom: 1px solid lighten(black, 85%)
    +dark-mode
      border-bottom: 1px solid darken(white, 75%)
    &:last-child
      border-bottom: none

    .title
      font-size: 24px
      color: inherit
      text-decoration: none


article.Article
  max-width: 800px
  +with-code-blocks

  h1
    font-size: calc(32px + 0.5vw)
    margin-top: 0

  .body
    font-size: calc(18px + 0.25vw)
    line-height: calc(1.5em + 0.2vw)
    img.screenshot
      +mac-shadow(0.66vw)

    h2, h3, h4
      margin-top: 2em

  +tablet-and-down
    header
      margin: 20px
      padding: 0
    .body
      > *
        margin-left: 20px
        margin-right: 20px
      > img
        max-width: calc(100% - 40px)
        // transform: scale(0.9)

  +laptop-and-up
    padding: 100px 0
    header
      margin: 0 60px 20px
    .body
      > *
        margin: 20px 60px
      img
        max-width: calc(100% - 120px)
