//
// colours
//
$bg-darkish: #444
$bg-dark: #333
$bg-darker: #222
$hr-light: rgba(white,0.2)
$text-lightish: rgba(white,0.5)
$text-light: rgba(white,0.66)
$text-lighter: rgba(white,0.8)

$bg-lightish: #f0f0f0
$bg-light: #f8f8f8
$bg-lighter: white
$hr-dark: rgba(black,0.2)
$text-darkish: lighten(black, 50%)
$text-dark: lighten(black, 40%)
$text-darker: lighten(black, 20%)

//
// breakpoints
//
$screen-phone: 576px
$screen-tablet: 768px
$screen-latop: 992px
$screen-desktop: 1200px


//
// mixins
//
=tablet-and-down
  @media only screen and (max-width: $screen-tablet)
    @content

=laptop-and-down
  @media only screen and (max-width: $screen-laptop)
    @content

=desktop-and-down
  @media only screen and (max-width: $screen-desktop)
    @content


=tablet-and-up
  @media only screen and (min-width: $screen-phone + 1)
    @content

=laptop-and-up
  @media only screen and (min-width: $screen-tablet + 1)
    @content

=desktop-and-up
  @media only screen and (min-width: $screen-laptop + 1)
    @content



=only-phone
  @media only screen and (max-width: $screen-phone)
    @content

=only-tablet
  @media only screen and (max-width: $screen-tablet) and (min-width: $screen-phone + 1)
    @content

=only-laptop
  @media only screen and (max-width: $screen-laptop) and (min-width: $screen-tablet + 1)
    @content


=hidden-phone
  +phone
    display: none

=hidden-tablet
  +tablet
    display: none

=hidden-laptop
  +laptop
    display: none

=hidden-desktop
  +desktop
    display: none


=light-mode
  @media (prefers-color-scheme: light)
    @content

=dark-mode
  @media (prefers-color-scheme: dark)
    @content

=mac-shadow($width: 5px)
  box-shadow: 0 $width $width*2 rgba(black, 0.33)

=with-code-blocks
  pre
    border: 0
    padding: 0
    +light-mode
      background: $bg-lighter
    +dark-mode
      background: $bg-dark

=soften-images
  img, iframe
    opacity: 0.75
    transition: opacity 0.2s
    &:hover
      opacity: 1
