@import 'variables'
.Header
  text-align: center
  font-size: 1.2em
  +tablet-and-down
    padding: 40px 0
  +laptop-and-up
    padding: 100px 60px 0
    text-align: right

    display: flex
    flex-direction: column
    align-items: flex-end
    justify-content: space-between
  img
    width: 120px
    border-radius: 50%
    margin-bottom: 0.5em
  p
    margin: 0.66em 0 0
  small
    font-size: 0.8em
  a
    color: inherit
    text-decoration: none

  .Links
    margin-top: 1em

  +light-mode
    background: $bg-light
    color: $text-dark
    strong
      color: $text-darker
    .about p
      a:hover
        color: darkblue
        border-top: 1px dashed rgba(darkblue, 0.5)
        border-bottom: 1px dashed rgba(darkblue, 0.5)

  +dark-mode
    +soften-images
    background: $bg-darker
    color: $text-light
    strong
      color: $text-lighter
    .about p
      a:hover
        color: lightblue
        border-top: 1px dashed rgba(lightblue, 0.5)
        border-bottom: 1px dashed rgba(lightblue, 0.5)
