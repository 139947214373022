@import 'variables'
html, body
  box-sizing: border-box
  margin: 0
  padding: 0
  font-size: 16px
  font-family: 'Helvetica', helvetica, sans-serif

  h1, h2, h3, h4
    font-family: 'Source Sans Pro', 'Helvetica', helvetica, sans-serif

.Application
  +tablet-and-down
    > main
      padding: 0
      .Projects .list-item
        margin: 2em 0 3em
      section
        margin: 100px 2em 0
        h1
          font-size: 18px
          margin-top: 0
          text-transform: uppercase
          color: lighten(black, 66%)
          +dark-mode
            color: darken(white, 50%)
        img
          max-width: 100%

  +laptop-and-up
    display: flex
    flex-direction: row
    > header
      flex: 1
      min-width: 250px
      max-width: 400px
      .about
        position: fixed
    > main
      flex: 2
      min-height: 100vh
      max-width: 800px

      section
        margin: 100px 60px 0
        h1
          font-size: 18px
          margin-top: 0
          text-transform: uppercase
          color: lighten(black, 66%)
          +dark-mode
            color: darken(white, 50%)
      .Projects
        display: flex
        flex-wrap: wrap
        justify-content: space-between
        .list-item
          display: inline-block
          margin-bottom: 2em
          width: calc(50% - 2em)


  +light-mode
    background: $bg-lighter
    color: $text-dark
    h1, h2, h3
      color: $text-darkish

  +dark-mode
    +soften-images
    background: $bg-dark
    color: $text-light
    strong
      color: $text-lighter
