@import 'variables'
.Video
  position: relative
  overflow: hidden
  // Calculated from the aspect ratio of the content (in case of 16:9 it is 9/16= 0.5625)
  // padding-top: 56.25%

  iframe
    position: absolute
    height: 100%
    width: 100%
    left: 0
    top: 0
    border: 0
