@import 'variables'
@import '/styles/prism-github.scss'
@import '/styles/prism-xonokai.scss'

.Projects
  a, a:hover
    text-decoration: none
    color: inherit

  .list-item
    transition: 0.2s all
    &:hover
      transform: scale(1.05)

    display: block
    &.pinned
      a:after
        content: ' *'

    figure
      max-width: 100%
      margin: 0
      img
        max-width: 100%
        margin: 0
        &.screenshot
          +mac-shadow
      figcaption
        font-size: 24px
        color: inherit
        text-decoration: none
        margin-top: 0.5em


article.Project
  max-width: 800px
  +with-code-blocks
  position: relative

  h1
    font-size: calc(32px + 0.5vw)
    margin-top: 0
    margin-bottom: 10px
  .info
    line-height: 2em
    color: lighten(black, 60%)
    > *
      +laptop-and-up
        margin-right: 1em
      +tablet-and-down
        display: block
    a
      color: inherit
      text-decoration: none
      &:hover
        color: lighten(black, 20%)
    svg
      fill: currentColor
      width: 20px
      margin: 0 10px 0 0
      vertical-align: middle

  .body
    a
      text-decoration: none
      color: darkblue
      +dark-mode
        color: lightblue
      &:hover
        border-top: 1px dashed darkblue
        border-bottom: 1px dashed darkblue
        +dark-mode
          border-top: 1px dashed lightblue
          border-bottom: 1px dashed lightblue

    pre
      font-size: 0.85em
      line-height: 1.66em

    font-size: calc(16px + 0.25vw)
    line-height: calc(1.5em + 0.2vw)
    img.screenshot
      +mac-shadow(0.66vw)

    h2, h3, h4
      margin-top: 2em
      a
        color: inherit
        &:hover
          text-decoration: none
          border: 0

    p code, ul code
      padding: 0 0.33em
      border-radius: 0.25em
      white-space: nowrap

      color: lighten(black, 30%)
      background: lighten(black, 95%)
      box-shadow: 0 0 1px 1px lighten(black, 80%)
      +dark-mode
        color: darken(white, 20%)
        background: darken(white, 75%)
        box-shadow: 0 0 1px 1px darken(white, 50%)

    blockquote.ux-sketch
      +mac-shadow(0.66vw)
      padding: 1em 2em
      margin-bottom: 2em

    aside
      display: block
      border-radius: 0.5em
      padding: 0.33em 1.33em
      background: lighten(black, 97%)
      +dark-mode
        background: darken(white, 85%)


    .analytics
      display: flex
      flex-wrap: wrap
      justify-content: space-between
      > figure
        display: inline-block
        margin: 0 0 2em 0
        img
          width: 100%
        figcaption
          text-align: center
          font-style: italic

  +tablet-and-down
    header
      margin: 20px
      padding: 0
    .body
      > *
        margin-left: 20px
        margin-right: 20px
      > img
        max-width: calc(100% - 40px)
        // transform: scale(0.9)

  +laptop-and-up
    padding: 100px 0
    header
      margin: 0 60px 20px
    .body
      > *
        margin: 20px 60px
      > img
        max-width: calc(100% - 120px)

      .analytics > figure
        width: calc(50% - 20px)
